import React from "react";
import { Box, Button, Flex, Link, Spacer, Text } from "@chakra-ui/react";
import { useState } from "react";
import contract from "../../util/contract-abi.json";
export let NumberTokenToMint = 0;

require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const contractAddress = "0xA241BD43E0B2ADF630771E77C7421f995FAD1C90";
const abi = contract.abi;
export const trump = new web3.eth.Contract(
  abi,
  contractAddress
);

const NavBar = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);
  const [newMintAmount, setNewMintAmount] = useState("");

  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(accounts);
    }
  }

  async function mintNftHandler() {
    try {
      const { ethereum } = window;
      const amount = newMintAmount;

      if (!amount || amount <= 0) {
        alert("Please input amount of tokens to mint");
      }

      if (ethereum) {

        const mintedAmount = await trump.methods.walletTokens(accounts[0]).call();
        const freeTokenPerWallet = await trump.methods.freeTokensPerWallet().call();
        const price = await trump.methods.price().call();
        let totalCost = String(price * amount);
        if (mintedAmount < freeTokenPerWallet) {
          totalCost = String(price * (amount - (freeTokenPerWallet-mintedAmount)));
        }

        await trump.methods
        .mint(amount)
        .send({
            to: contractAddress,
            from: accounts[0],
            value: totalCost
        })
      } else {
        console.log("Please install Metamask");
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Flex
      justify="space-evenly"
      align="center"
      padding="30px"
      position="fixed"
      top="0"
      width="100%"
      zIndex="1000"
    >
      <Flex
        justify="space-around"
        align="center"
        padding="30px 30px 30px 30px"
        flexBasis="30vw"
      >
        <Box margin="0 15px" textShadow="0 3px #000000">
          <Link
            href="https://opensea.io/collection/your-favorite-president-is-back"
            isExternal
            color="white"
            textDecorationColor="none"
            textDecorationLine="none"
            fontSize="24px"
          >
            OpenSea
          </Link>
        </Box>
        <Spacer />
        <Box margin="0 15px" textShadow="0 3px #000000">
          <Link
            href="https://twitter.com/ImBackNFT"
            isExternal
            color="white"
            textDecorationColor="none"
            textDecorationLine="none"
            fontSize="24px"
          >
            Twitter
          </Link>
        </Box>
        <Spacer />
      </Flex>
      <Box>
        <Text style={{ color: "white" }} fontSize="32px">
          Your Favorite President Is Back
        </Text>
      </Box>
      <Flex
        justify="space-around"
        align="center"
        flexDirection="column"
        flexBasis="30vw"
        alignItems="flex-end"
        padding="30px 30px 30px 30px"
      >
        {isConnected ? (
          <Flex
            justify="space-around"
            align="center"
          >
            <Box margin="0 15px" textShadow="0 3px #000000">
              <select
                style={{
                  backgroundColor: "#000000",
                  borderRadius: "5px",
                  color: "white",
                  cursor: "pointer",
                  fontFamily: "inherit",
                  padding: "15px",
                  margin: "0 15px",
                  border: "none"
                }}
                onChange={(e) => {
                  setNewMintAmount(e.target.value);
                  NumberTokenToMint = e.target.value;
                }}
                value={newMintAmount}
              >
                <option className="full-text" disabled="" value="">
                  Amount
                </option>
                <option type="number" value="1">
                  1
                </option>
                <option type="number" value="2">
                  2
                </option>
                <option type="number" value="3">
                  3
                </option>
                <option type="number" value="4">
                  4
                </option>
                <option type="number" value="5">
                  5
                </option>
                <option type="number" value="6">
                  6
                </option>
                <option type="number" value="7">
                  7
                </option>
                <option type="number" value="8">
                  8
                </option>
                <option type="number" value="9">
                  9
                </option>
                <option type="number" value="10">
                  10
                </option>
                <option type="number" value="11">
                  11
                </option>
                <option type="number" value="11">
                  12
                </option>
                <option type="number" value="11">
                  13
                </option>
                <option type="number" value="11">
                  14
                </option>
                <option type="number" value="11">
                  15
                </option>
                <option type="number" value="11">
                  16
                </option>
                <option type="number" value="11">
                  17
                </option>
                <option type="number" value="11">
                  18
                </option>
                <option type="number" value="11">
                  19
                </option>
                <option type="number" value="11">
                  20
                </option>
              </select>
            </Box>
            <Spacer />
            <Box
              backgroundColor="#000000"
              borderRadius="5px"
              boxShadow="0px 2px 2px 1px #0F0F0F"
              color="white"
              cursor="pointer"
              fontFamily="inherit"
              padding="15px"
              margin="0 15px"
              fontSize="24px"
              onClick={mintNftHandler}
            >
              Mint
            </Box>
            <Spacer />
          </Flex>
        ) : (
          <Button
            backgroundColor="#000000"
            borderRadius="5px"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            color="white"
            cursor="pointer"
            fontFamily="inherit"
            padding="15px"
            margin="0 15px"
            fontSize="24px"
            onClick={connectAccount}
          >
            Connect
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default NavBar;
