import React from "react";
import { Flex, Box, Text, Image } from "@chakra-ui/react";
import Img1 from "../../assets/images/trump/1.png";
import Img2 from "../../assets/images/trump/2.png";
import Img3 from "../../assets/images/trump/3.png";
import Img4 from "../../assets/images/trump/4.png";
import Img5 from "../../assets/images/trump/5.png";
import Img6 from "../../assets/images/trump/6.png";
import Img7 from "../../assets/images/trump/7.png";
import Img8 from "../../assets/images/trump/8.png";
import Img9 from "../../assets/images/trump/9.png";
import Img10 from "../../assets/images/trump/10.png";

const Grid = () => {
  return (
    <Box width="100%" margin="auto" paddingBottom="5vh" backgroundColor="#49acd7">
      <Text height="48px" fontSize="48px" margin="48px">
        Gallery
      </Text>
      <Flex justifyContent="space-evenly">
        <Image src={Img1} width="200px"></Image>
        <Image src={Img2} width="200px"></Image>
        <Image src={Img3} width="200px"></Image>
        <Image src={Img4} width="200px"></Image>
        <Image src={Img5} width="200px"></Image>
      </Flex>
      <Flex justifyContent="space-evenly" marginTop="5vh">
        <Image src={Img6} width="200px"></Image>
        <Image src={Img7} width="200px"></Image>
        <Image src={Img8} width="200px"></Image>
        <Image src={Img9} width="200px"></Image>
        <Image src={Img10} width="200px"></Image>
      </Flex>
    </Box>
  );
};

export default Grid;
