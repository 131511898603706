import React from "react";
import TrumpIsBack from "../../assets/images/trump/gif.gif";
export default function BackgroundVideo() {
  return (
    <div className="bg-container">
      <div className="container">
        <div className="mb-5 mb-lg-0 row box-img ">
          <div className="px-0 col-12">
            <div className="mb-4 mt-4 mt-lg-5 mb-lg-5 container">
              <div className="row">
                <div className="px-0 col-12">
                  <img src={TrumpIsBack} alt="" className="mx-auto w-90" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
