import React from "react";
import {
  Text,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const FAQ = () => {
  return (
    <Box width="100%" margin="auto" paddingBottom="17vh">
      <Text height="48px" fontSize="48px" margin="48px">
        FAQ
      </Text>
      <Accordion allowMultiple>
        <AccordionItem>
          <h2 style={{ margin: "20px" }}>
            <AccordionButton
              backgroundColor="white"
              fontSize="28px"
              fontFamily="Bubble"
              borderRadius="10px"
              color="#0778F6"
              padding="10px"
            >
              <Box flex="1" textAlign="left">
                1. What is the mint price?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontFamily="Roboto Condensed" fontSize="18px">
            One free or additional paid at 0.003 ETH.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2 style={{ margin: "20px" }}>
            <AccordionButton
              backgroundColor="white"
              fontSize="28px"
              fontFamily="Bubble"
              borderRadius="10px"
              color="#0778F6"
              padding="10px"
            >
              <Box flex="1" textAlign="left">
                2. What is the supply?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontFamily="Roboto Condensed" fontSize="18px">
            3,333 will be up for grabs.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2 style={{ margin: "20px" }}>
            <AccordionButton
              backgroundColor="white"
              fontSize="28px"
              fontFamily="Bubble"
              borderRadius="10px"
              padding="10px"
              color="#0778F6"
            >
              <Box flex="1" textAlign="left">
                3. What's the team like?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontFamily="Roboto Condensed" fontSize="18px">
            We are the team that hate Trump but kinda miss his ranting on Twitter.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default FAQ;
