import { Trump } from "./components/trump/TrumpIsBack";

function App() {
  return (
    <div className="main-app">
      <Trump />
    </div>
  );
}

export default App;
