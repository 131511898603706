import React, { useState } from "react";
import BackgroundVideo from "./Background";
import Grid from "./Grid";
import MainMint from "./MainMint";
import NavBar from "./Navbar";
import "../../assets/css/trumpisback.css"
export const Trump = () => {
  const [accounts, setAccounts] = useState([]);
  return (
    <div className="wrapper">
      <div>
        <NavBar accounts={accounts} setAccounts={setAccounts} />
      </div>
      <BackgroundVideo></BackgroundVideo>
      <MainMint />
      <Grid></Grid>
    </div>
  );
};
