import { Box, Flex, Image } from "@chakra-ui/react";
import NFTgif from "../../assets/images/trump/NFT.gif";
import FAQ from "./FAQ";

const MainMint = () => {
  return (
    <Flex justifyContent="space-evenly" alignItems="center" paddingTop="10vh">
      <Box width="65vw" margin="5vw" marginTop="auto">
        <FAQ></FAQ>
      </Box>
      <Box width="35vw" margin="5vw" marginTop="0vw">
        <Image width="35vw" src={NFTgif}></Image>
      </Box>
    </Flex>
  );
};

export default MainMint;
